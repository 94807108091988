p {
    margin-bottom: 0;
}

.footer {
    z-index: 10;
    width: 100%;
    min-height: 96px;

    background-color: var(--main-color);
    border-top: var(--border-block);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer__link {
    display: flex;
    flex-direction: column;
}

.footer__link_a {
    display: flex;
    justify-content: center;
    gap: 12px;
}