.header {
    z-index: 10;
    width: 100%;

    padding: var(--padding-block);

    background-color: var(--balans-color);
    
    border-bottom: var(--border-block);
    opacity: var(--opacity-block);
}

.header__logo {
    position: relative;
}

.header__logo::after {
    content: attr(data-logo);
    position: absolute;
    -webkit-text-stroke: 0.12em var(--main-color);
    left: 0;
    right: 0;
    top: 0;
    z-index: -2;
    font-size: 1em;
}

.header__container {
    display: flex;
    justify-content: space-between;
}

.header__quote {
    text-align: end;
}

q {
    font-style: italic;
}

@media (max-width: 576px) {
    .header__quote {
        display: none;
    }
}