.background {
    position: absolute;

    width: 100%;
    height: 100%;
}

.map {
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 7;
    position: fixed;

    width: 100%;
    height: 100%;
    background-color: #bceeb3;
}

.map__svg {
    background-image: url('./new_map_5.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

@media(max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    
}

@media (max-width: 576px) {
    .map__svg {
        position: fixed;
        height: 734px;
        width: auto;
    }
}