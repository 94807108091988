.calendar {
    z-index: 10;
    width: 100%;
    /* max-width: 900px; */
}

.calendar__header {
    /* color: gainsboro; */
    padding-top: 14px;
    text-align: center;
}

.calendar__body {
    padding: 28px;

    display: flex;
    flex-direction: row;
    gap: 20px;

    align-items: baseline;
}

.calendar__fullCalendar {
    width: 100%;

    padding: 10px;

    background-color: #dcdcdccc;
    border-radius: 10px;
    /* max-width: 700px; */
}

@media(max-width: 1200px) {
    /* .calendar__body {
        flex-direction: column;
        align-items: center;
    } */
}