.description {
    z-index: 10;
    width: 100%;
    flex: 1 1 auto;
    
    padding: var(--padding-block);
    
    display: flex;
    flex-direction: column;
    gap: 80px;
}

@media(max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {
    .description {
        gap: 40px;
    }
}