.containerViewRide {
    z-index: 6;
    margin: 0 auto;

    padding: var(--padding-block);
    background-color: var(--balans-color);
    border-radius: var(--radius-block);
    border-bottom: var(--border-block);
    opacity: var(--opacity-block);
    outline: 2px solid var(--main-color);
    outline-offset: 2px;
}