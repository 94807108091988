/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  --main-color: #3D5220;
  --balans-color: #B7CB99;
  --second-color: #778FD2;
  --third-color: #2A3759;
  --auxiliary-color: #431D32;
  --radius-block: 30px;
  --border-block: 3px solid var(--second-color);
  --opacity-block: 0.85;
  --padding-block: 20px;
  --gap: 14px;

  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  height: 100%;
}

/* Remove default margin in favour of better control in authored CSS */
body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core body defaults */
body {
  height: 100%;
  /* min-height: 100vh; */
  line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4,
button, input, label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2,
h3, h4 {
  text-wrap: balance;
}

h1 {
  font-weight: 700;
  color: var(--second-color);
  font-family: "Kreadon-Bold", sans-serif;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select {
  font: inherit;
}

/* Make sure textarea without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

@font-face {
  font-family: "Kreadon-ExtraLight";
  src: url("./font/Kreadon/Kreadon-ExtraLight.ttf");
}

@font-face {
  font-family: "Kreadon-Regular";
  src: url("./font/Kreadon/Kreadon-Regular.ttf");
}

@font-face {
  font-family: "Kreadon-Bold";
  src: url("./font/Kreadon/Kreadon-Bold.ttf");
}

#root {
  height: 100%;
  font-family: "Kreadon-Regular", sans-serif;
  background-color: ghostwhite;
}
