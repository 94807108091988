.ride {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid black;
    padding-bottom: 10px;
    gap: 14px;
}

.rideListContainer {
    display: flex;
    gap: 20px;
}

.btnGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
