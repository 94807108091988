.viewRide {
    z-index: 10;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap);

    padding: var(--padding-block);
    margin: 0 auto;

    background-color: var(--balans-color);
    border-radius: var(--radius-block);
    border-bottom: var(--border-block);
    opacity: var(--opacity-block);
    outline: 2px solid var(--main-color);
    outline-offset: 2px;

    text-align: center;
}

.viewRide__events {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.events__group {
    background-color: #e5f3ee;

    border: 1px solid black;
    border-radius: var(--radius-block);

    padding: var(--padding-block);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap);
}

.currentDate::before {
    content: "⇒";
    position: absolute;
    left: -22px;
}

.events__line {
    height: 30px;
    border-left: 2px solid black;
}

.events__container {
    display: flex;
    flex-direction: row;

    gap: var(--gap);
}

.event {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 8px; */
}

.event__point-container {
    position: relative;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* gap: 8px; */
}

.event__point {
    width: 100px;

    position: relative;

    border: 3px solid #3835B9;
    border-radius: 16px;

    text-align: center;
    color: white;

    background-color: cornflowerblue;

    padding: 0 8px;
    margin-bottom: 8px;
}

.event__point::before {
    content: '\1F6A9';
    position: absolute;
    left: 44%;
    bottom: 24px;
}

.event__btn {
    padding: 2px 18px;
    transition: transform 0.2s, color 0.2s;
    will-change: transform;
}

.event__btn:hover {
    transform: scale(1.05);
}

@media(max-width: 1200px) {}

@media (max-width: 992px) {
    .events__container {
        flex-direction: column;
    }
}

@media (max-width: 768px) {

}

@media (max-width: 576px) {
    .events__group {
        padding: 6px 6px;
        gap: 6px;
    }

    .currentDate::before {
        left: -18px;
    }
}

@media (max-width: 444px) {
    .viewRide__events {
        width: 100%;
    }
    .event__dashed {
        width: 72px;
    }
}